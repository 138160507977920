document.addEventListener('shopAppMounted', () => {
  // if (process.env.NODE_ENV == 'development') { return; }
  loadMetrica();
  // loadFBPixel();

  // fbq('init', '1905590482988136');
  // fbq('track', 'PageView');
  // loadCustomFBChat();
});

function loadMetrica() {
  // ym = undefined;
  (function(m, e, t, r, i, k, a) {
    m[i] = m[i] || function() {
      (m[i].a = m[i].a || []).push(arguments);
    };
    m[i].l = 1 * new Date();
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  ym(36471355, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true,
    ecommerce: 'dataLayer'
  });
}

// function loadFBPixel() {
  // ! function(f, b, e, v, n, t, s) {
    // if (f.fbq) return;
    // n = f.fbq = function() {
      // n.callMethod ?
        // n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    // };
    // if (!f._fbq) f._fbq = n;
    // n.push = n;
    // n.loaded = !0;
    // n.version = '2.0';
    // n.queue = [];
    // t = b.createElement(e);
    // t.async = !0;
    // t.src = v;
    // s = b.getElementsByTagName(e)[0];
    // s.parentNode.insertBefore(t, s);
  // }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
// }

// function loadCustomFBChat() {
  // (function(d, s, id) {
    // var js, fjs = d.getElementsByTagName(s)[0];
    // if (d.getElementById(id)) return;
    // js = d.createElement(s);
    // js.id = id;
    // js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js';
    // fjs.parentNode.insertBefore(js, fjs);
  // }(document, 'script', 'facebook-jssdk'));
// }
